<template>
  <div class="d-flex flex-column mr-3">
    <vue-easy-lightbox
      :visible="lightboxVisible"
      :imgs="getSelectedCreativeUrls"
      :index="lightboxIndex"
      zoom-disabled
      rotate-disabled
      move-disabled
      @hide="lightboxVisible = false"
    />

    <Panel
      size="small"
      loader-text="Loading Creatives"
      :loading="loadingCreatives"      
    >
      <template v-slot:header>
        <div>
          <span data-cy="heading">Creatives</span>
        </div>      

        <b-button
          variant="primary"
          size="sm"
          class="mt-3"
          @click="redirectToUpload"
        >
          <font-awesome-icon
            class="mr-1"       
            :icon="['fas', 'plus']"
          />
          Upload creative
        </b-button>
      </template>


      <template v-slot:content>
        <b-table
          :fields="fields"          
          :items="computedCreatives"
          responsive
          thead-class="text-nowrap"
          tbody-tr-class="text-nowrap"
          primary-key="id"
        >
          <template #cell(status)="row">
            <b-badge
              tag="div"
              class="w-100 py-1"
              :variant="getStatusMap(row.item).variant"
            >
              {{ startCase(row.item.computedStatus) }}
            </b-badge>            
          </template>
          
          <template #cell(stats)="row">
            <strong><i>{{ getStatsStatus(row.item.stats) }}</i></strong>
          </template>

          <template #cell(id)="row">            
            <div class="d-flex align-items-center">
              <div
                class="text-truncate fixed-width-100 mr-2"
                :title="row.item.id"
              >
                {{ row.item.id }}
              </div>
                  
              <font-awesome-icon
                role="button"
                :icon="['fas', 'copy']"
                @click="copyIdToClipboard(row.item.id)"
              />
            </div>          
          </template>

          <template #cell(name)="row">
            {{ row.item.name }}
          </template>         

          <template #cell(externalId)="row">
            {{ row.item.externalId }}
          </template>

          <template #cell(cmsId)="row">
            {{ row.item.cmsId }}
          </template>

          <template #cell(landingPageUrl)="row">
            {{ row.item.landingPageUrl }}
          </template>

          <template #cell(categoryName)="row">
            {{ row.item.categoryName }}
          </template>

          <template #cell(trafficSourceName)="row">
            {{ row.item.trafficSourceName }}
          </template>

          <template #cell(country)="row">
            {{ row.item.countryCode }}
          </template>

          <template #cell(language)="row">
            {{ row.item.languageCode }}
          </template>

          <template #cell(actions)="row">
            <b-button
              v-if="row.item.status=='Approved'"
              :variant="row.item.enabled ? 'warning' : 'success'"
              size="sm"
              class="mr-2"
              @click="row.item.enabled ? showDisableModal(row.item) : showEnableModal(row.item)"
            >
              {{ row.item.enabled ? "Withdraw creative" : "Enable creative" }}
            </b-button>
            <ButtonLoader
              v-if="row.item && row.item.files && row.item.files.length > 0"
              variant="info"
              size="sm"
              :custom-button="false"
              :loading="loadingFiles"
              show-text-with-loader
              @click="loadCreative(row.item.id)"
            >
              Show Files
            </ButtonLoader>
          </template>
        </b-table>
      </template>
    </Panel>

    <b-modal
      id="confirm-enable-creative-modal"
      ref="confirm-enable-creative-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Confirm to enable creative"
      ok-title="Confirm"
      button-size="sm"
      @close="hideEnableCreativeModal" 
    >
      <div
        v-if="selectedCreative"
      >
        <p        
          class="mb-2"
        >
          Are you sure you want to confirm that calls might be coming from creative <strong>{{ selectedCreative.name }}</strong>? 
        </p>
        <p
          v-if="!!selectedCreative.isMedicare" 
        >
          Only buyers who have explicitly approved this creative will receive your calls from creative <strong>{{ selectedCreative.name }}</strong>
        </p>
        <p
          v-else
        >
          Only buyers who have explicitly approved this creative will receive your calls in category <strong>{{ selectedCreative.categoryName }}</strong> and traffic source <strong>{{ selectedCreative.trafficSourceName }}</strong>
        </p>
      </div>

      <template #modal-footer>
        <b-button
          type="button"
          variant="secondary"
          size="sm"          
          @click="hideEnableCreativeModal"
        >
          Cancel
        </b-button>

        <BaseButtonLoader
          :loading="enableCreativeLoading"
          class="ml-2"
          type="button"
          :custom-button="false"
          size="sm"
          variant="primary"
          @click="confirmEnableCreative"
        >
          Confirm to enable
        </BaseButtonLoader>
      </template>
    </b-modal>

    <b-modal
      id="confirm-disable-creative-modal"
      ref="confirm-disable-creative-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Confirm to withdraw creative"
      ok-title="Confirm"
      button-size="sm"
      @close="hideDisableCreativeModal" 
    >
      <p
        v-if="selectedCreative"
        class="mb-2"
      >
        Are you sure you want to confirm that no more calls will be coming from creative <strong>{{ selectedCreative.name }}</strong>?
      </p>
      <template #modal-footer>
        <b-button
          type="button"
          variant="secondary"
          size="sm"          
          @click="hideDisableCreativeModal"
        >
          Cancel
        </b-button>

        <BaseButtonLoader
          :loading="enableCreativeLoading"
          class="ml-2"
          type="button"
          :custom-button="false"
          size="sm"
          variant="primary"
          @click="confirmDisableCreative"
        >
          Confirm to withdraw
        </BaseButtonLoader>
      </template>
    </b-modal>
  </div>
</template>
      
<script>

import { showErrorMessage, showSuccessMessage } from '@/notification-utils'
import formInputMixin from '@/mixins/formInputMixin'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import { mapActions, mapState, mapGetters } from 'vuex'
import VueEasyLightbox from 'vue-easy-lightbox'
      
export default {
  name: 'SellerCreatives',

  components: {
    VueEasyLightbox,  },
      
  title: 'Creatives', 
    
  mixins: [formInputMixin, copyToClipboardMixin],
      
  data() {
    return {
      loadingCreatives: false, 
      lightboxVisible: false,
      lightboxIndex: 0,
      loadingFiles: false,
      selectedCreative: null,
      enableCreativeLoading: false,
      fields: [     
        { key: 'status', label: 'Status', sortable: true }, 
        { key: 'stats', label: 'Buyers\' Approvals', sortable: false },
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Name', sortable: true },       
        { key: 'externalId', label: 'Creative ID', sortable: true },       
        { key: 'cmsId', label: 'CMS ID', sortable: true },      
        { key: 'landingPageUrl', label: 'Landing Page URL', sortable: true },
        { key: 'categoryName', label: 'Category', sortable: true },
        { key: 'trafficSourceName', label: 'Traffic Source', sortable: true },       
        { key: 'country', label: 'Country', sortable: true },
        { key: 'language', label: 'Language', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }         
      ]
    }
  },

  computed: {
    ...mapState('creative', [ 'creatives' ]),
    ...mapGetters('creative', ['getSelectedCreativeUrls']),

    computedCreatives() {
      return this.creatives.map((creative) => {
        return {
          ...creative,
          computedStatus: creative.status === 'Approved' ? (creative.enabled && 'Enabled') || 'Disabled' : `${creative.status} by Ringba X Admin`
        }
      })
    }
  },
  
  async created() {
    try {      
      await this.getCreatives()
    } catch (error) {
      showErrorMessage(error.response?.data?.title)
    }
  },

  methods: {
    ...mapActions('creative', [ 'getCreatives','getCreative', 'enableCreative', 'disableCreative' ]),

    getStatsStatus(stats) {
      if (!stats) {
        return 'None'
      }

      let status = ''

      if (stats.approved === 'Some') {
        status = 'Few Approved'
      }

      if (stats.rejected === 'Some') {
        if (status) {
          status += ' | '
        } 

        status += 'Few Rejected'
      }

      if (stats.pending === 'Some') {
        if (status) {
          status += ' | '
        } 

        status += 'Few Pending Review'
      }

      if (stats.pending === 'All') {
        status = 'No Buyers Reviewed'
      }

      if (stats.rejected === 'All') {
        status = 'All Buyers Rejected'
      }

      if (stats.Approved === 'All') {
        status = 'All Buyers Approved'
      }

      return status
    },

    copyIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('ID copied to clipboard')
    },

    getStatusMap(creative) {
      const statusToVariantMap = {
        ReviewPending: {variant:'secondary'},
        Rejected: {variant:'danger'},
        Approved: {variant:'success'}
      }

      if (creative.status === 'Approved') {
        return creative.enabled ? { variant:'success' } : { variant:'warning' }
      }

      return statusToVariantMap[creative.status] ?? {variant:'dark'}
    },

    async loadCreative(id){
      try {
        this.loadingFiles = true
        await this.getCreative(id)
        this.lightboxVisible = true
      } catch (error) {
        showErrorMessage("Failed to open files")
      } finally {
        this.loadingFiles = false
      }
    },

    redirectToUpload() {
      this.$router.push({ name: 'UploadCreative' })
    },

    showEnableModal(creative) {
      this.selectedCreative = creative
      this.$bvModal.show('confirm-enable-creative-modal')
    },
    
    showDisableModal(creative) {
      this.selectedCreative = creative
      this.$bvModal.show('confirm-disable-creative-modal')
    },

    async confirmEnableCreative() {
      try {
        this.enableCreativeLoading = true
        await this.enableCreative(this.selectedCreative.id)
        showSuccessMessage('Successfully enabled creative')
      } catch (error) {
        showErrorMessage("Failed to enable creative")
      } finally {
        this.enableCreativeLoading = false
        this.selectedCreative = null
        this.hideEnableCreativeModal()        
      }
    },

    async confirmDisableCreative() {
      try {
        this.enableCreativeLoading = true
        await this.disableCreative(this.selectedCreative.id)
        showSuccessMessage('Successfully withdrawn creative')
      } catch (error) {
        showErrorMessage("Failed to withdraw creative")
      } finally {
        this.enableCreativeLoading = false
        this.selectedCreative = null
        this.hideDisableCreativeModal()
      }
    },

    hideEnableCreativeModal() {
      this.$bvModal.hide('confirm-enable-creative-modal')
    },

    hideDisableCreativeModal() {
      this.$bvModal.hide('confirm-disable-creative-modal')
    }
  }
}
</script>
<style lang="scss" scoped>
</style>